import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Select from "react-select";
import { SaathiService } from "../../../service/saathi.service";
import { CryptoState } from "../../FarmerContext";
import { Spinner } from "react-bootstrap";

const KycDetails = () => {
  const {
    setKycDetail,
    SetCorporate,
    setCompletedSection,
    userDetails,
    dropdownData,
  } = CryptoState();
  const Api_Url = process.env.REACT_APP_API_URL;
  const [date, setDate] = useState("");
  const [MaritalStatus, setMaritalStatus] = useState("");
  const [OccupationName, setOccupationName] = useState("");
  const [Relationship, setRelationship] = useState("");
  // const [OccupationId, setOccupationId] = useState("");
  const [OtherDocName, setOtherDocName] = useState([]);
  const [StatesCode, setStatesCode] = useState("");
  const [StateName, setStateName] = useState("");
  const [DistrictCode, setDistrictCode] = useState("");
  const [DistrictName, setDistrictName] = useState("");
  const [BlockCode, setBlockCode] = useState("");
  const [BlockName, setBlockName] = useState("");
  const [DistrictList, setDistrictList] = useState([]);
  const [BlockList, setBlockList] = useState([]);
  const [statedatalist, setstatedatalist] = useState({});
  const [changeEvent, SetChangeEvent] = useState("");
  const [isDisabled, setIsDisabled] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState("");

  const MasterDropDown = dropdownData?.data?.results[0];
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    reset({
      gender: userDetails?.personal_details?.gender,
    });
  }, [userDetails]);

  const customStyles = {
    control: (base) => ({
      ...base,
      // height: 50,
      minHeight: 20,
    }),
  };
  // console.log(statedatalist, "kikiki");

  const optionStatesCode = [
    {
      label: "State",
      options:
        statedatalist?.data &&
        statedatalist?.data?.results?.map((state) => ({
          label: `${state.state_name}`,
          value: `${state.state_code}`,
        })),
    },
  ];
  const optionDistrictCode = [
    {
      label: "District",
      options:
        DistrictList?.data &&
        DistrictList?.data?.results?.map((district) => ({
          label: `${district.district_name}`,
          value: `${district.district_code}`,
        })),
    },
  ];
  const optionBlockCode = [
    {
      label: "Block",
      options:
        BlockList?.data &&
        BlockList?.data?.results?.map((block) => ({
          label: `${block.block_name}`,
          value: `${block.block_code}`,
        })),
    },
  ];
  const OccupationListData = [
    {
      label: "Occupation",
      options:
        MasterDropDown &&
        MasterDropDown?.occupation?.map((OCP, id) => ({
          label: `${OCP}`,
          value: `${id}`,
        })),
    },
  ];

  function handleMaritalStatus(MaritalStatus) {
    setMaritalStatus(MaritalStatus);
  }

  function handleOccupationName(OccupationObject) {
    setOccupationName(OccupationObject.label);
  }

  function handleRelationship(Relationship) {
    setRelationship(Relationship);
  }

  function handleStatesDropdown(StateObject) {
    setStatesCode(StateObject.value);
    setStateName(StateObject.label);
    setLoader(true);
  }

  function handleDistrictCode(DistrictObject) {
    setDistrictCode(DistrictObject.value);
    setDistrictName(DistrictObject.label);
  }

  function handleBlockCode(BlockObject) {
    setBlockCode(BlockObject.value);
    setBlockName(BlockObject.label);
  }

  function handlOtherDocName(OtherDocName) {
    setOtherDocName(OtherDocName);
    SetChangeEvent(OtherDocName);
    setIsDisabled(OtherDocName);
  }
  const Occupations = {
    Agriculture: 1,
    "Dairy Farmer": 3,
    Other: 6,
    "Private Sector": 4,
    "Public Sector": 5,
    Trader: 2,
  };
  // Marital Status selectbox
  const MaritalStatusList = [
    { value: "single", label: "Unmarried" },
    { value: "married", label: "Married" },
    { value: "divorced", label: "Divorced" },
    { value: "Widow / widower", label: "Widower / widower" },
  ];

  //   Relationship Status selectbox
  const RelationshipList = [
    {
      label: "Relationship",
      options:
        MasterDropDown &&
        MasterDropDown.relations.map((Relation) => ({
          label: `${Relation}`,
          value: `${Relation}`,
        })),
    },
  ];
  // Document selectbox
  const DocumenttypeListData = [
    {
      label: "Document",
      options: [
        { value: "", label: "Select-Document" },
        { value: "voter_card", label: "Voter ID" },
        { value: "driving_licence", label: "Driving License" },
        { value: "ration_card", label: "Ration Card" },
        { value: "bank_document", label: "Bank Document" },
      ],
    },
  ];

  const inputFields = document.querySelectorAll("input.field");
  inputFields.forEach((field) => {
    field.addEventListener("input", handleInput);
  });

  function handleInput(e) {
    let inputField = e.target;
    if (inputField.value.length >= 1) {
      let nextField = inputField.nextElementSibling;
      return nextField && nextField.focus();
    }
  }

  useEffect(() => {
    SaathiService.getMasterstateData()
      .then((data) => setstatedatalist(data))
      .catch((error) => {
        console.warn("Not data fetch :(");
      });
  }, []);

  useEffect(() => {
    if (StatesCode !== null) {
      SaathiService.getMasterdistrictsData(StatesCode)
        .then((data) => {
          setDistrictList(data);
          setLoader(false);
        })
        .catch((error) => {
          console.warn("Not good data fetch :(");
        });
    }

    if (DistrictCode !== null) {
      SaathiService.getMasterblocksData(DistrictCode)
        .then((data) => {
          setBlockList(data);
        })
        .catch((error) => {
          console.warn("Not good data fetch :(");
        });
    }
  }, [StatesCode, DistrictCode, BlockCode]);

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    setValue("dateOfBirth", selectedDate);
  };

  const onSubmit = (data) => {
    if (data.gender == null || undefined) {
      Swal.fire("Please Select Gender");
      return;
    }
    setLoading(true);
    SetCorporate(true);
    // data["dateOfBirth"] = date;
    localStorage.setItem("userDetail", JSON.stringify(data));
    let user_token = localStorage.getItem("token");
    const User_Mobile = localStorage.getItem("phone_number");

    var userId = localStorage.getItem("user-info-id");
    var formdata = new FormData();
    formdata.append(
      "dateOfBirth",
      data.dateOfBirth || userDetails?.personal_details?.dateOfBirth
    );
    formdata.append(
      "gender",
      data.gender || userDetails?.personal_details?.gender
    );
    formdata.append(
      "martialStatus",
      MaritalStatus || userDetails?.personal_details?.martialStatus
    );
    formdata.append(
      "noOfMember",
      data.noOfMember || userDetails?.personal_details?.noOfMember
    );
    formdata.append(
      "occupation",
      OccupationName || userDetails?.personal_details?.occupation
    );
    formdata.append(
      "occupation_id",
      Occupations[OccupationName] ||
        userDetails?.personal_details?.occupation_id
    );
    formdata.append(
      "aadharNumber",
      data.aadharNumber || userDetails?.personal_details?.aadharNumber
    );
    formdata.append(
      "panNumber",
      data.panNumber || userDetails?.personal_details?.panNumber
    );
    formdata.append("phoneNumber", User_Mobile);
    formdata.append(
      "address",
      data.address || userDetails?.personal_details?.address
    );
    formdata.append("city", BlockName || userDetails?.personal_details?.city);
    formdata.append("city_id", BlockCode);
    formdata.append("state", StateName || userDetails?.personal_details?.state);
    formdata.append("state_id", StatesCode);
    formdata.append(
      "district",
      DistrictName || userDetails?.personal_details?.district
    );
    formdata.append("district_id", DistrictCode);
    formdata.append(
      "nomineeName",
      data.nomineeName || userDetails?.personal_details?.nomineeName
    );
    formdata.append(
      "nomineeRelationship",
      Relationship || userDetails?.personal_details?.nomineeRelationship
    );
    formdata.append(
      "pincode",
      data.pincode || userDetails?.personal_details?.pincode
    );
    formdata.append("ui_section_id", "2");

    for (let d = 0; d < data.profile_picture.length; d++) {
      formdata.append("profile_picture", data.profile_picture[d]);
    }
    if (data.dateOfBirth == null || undefined) {
      Swal.fire("Please Select Date of Birth");
    }
    if (data.panNumber && OtherDocName == null) {
      alert("please select either PAN or Other Document");
    }

    for (let a = 0; a < data.aadhar_card.length; a++) {
      formdata.append("aadhar_card", data.aadhar_card[a]);
    }

    if (data.pan_card.length > 0) {
      for (let c = 0; c < data.pan_card.length; c++) {
        formdata.append("pan_card", data.pan_card[c]);
      }
    }
    //other Document
    if (data.other_document == undefined) {
      formdata.append("other_document", []);
    } else {
      for (let b = 0; b < data.other_document.length; b++) {
        formdata.append("other_document", data.other_document[b]);
      }
    }
    //other document Number
    if (data.otherDocumentNumber == undefined) {
      formdata.append("otherDocumentNumber", "");
    } else {
      formdata.append("otherDocumentNumber", data.otherDocumentNumber);
    }

    //othet document type
    if (OtherDocName == undefined || OtherDocName.length == 0) {
      formdata.append("other_document_type", "");
    } else {
      formdata.append("other_document_type", OtherDocName);

      if (
        data.otherDocumentNumber.trim() == "" ||
        data.otherDocumentNumber.trim() == null
      ) {
        Swal.fire("Please Enter Other Document Number");
        return;
      }
    }
    formdata.append("created_by", "0");
    formdata.append("created_by_name", "Self");

    //for printing
    for (var pair of formdata.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    var requestOptions = {
      method: "PUT",
      body: formdata,
      headers: { Authentication: `Token ${user_token}` },
    };

    fetch(`${Api_Url}/api/kyc-details/${userId}`, requestOptions)
      .then((r) => r.json())
      .then((result) => {
        if (result.status === 200) {
          Swal.fire({
            icon: "success",
            title: result.message,
            timer: 1500,
          });
          setKycDetail(true);
          setCompletedSection("2");
          setLoading(false);
        } else {
          Swal.fire({
            icon: "warning",
            title: result.message,
          });
          setLoading(false);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <section className="sign_in_area col-lg-12">
      <div className="px-4">
        <div className="login_info pl-0">
          <h2 className="f_p f_600 f_size_24 t_color3 mb_40 mt_20 text-center">
            Fill the <span className="f_700 orange">KYC Details</span> in
            Application
          </h2>

          <div className="formdetails">
            <form action="#" className=" login-form sign-in-form">
              <div className="row">
                <div className="col-lg-6 form-group text_box">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Date Of Birth <small style={{ color: "#ff0000" }}>*</small>
                  </label>
                  <input
                    className="form-control d-block w"
                    name="dateOfBirth"
                    type="date"
                    onChange={handleDateChange}
                    // value={date}
                    {...register("dateOfBirth", {
                      required:
                        date || userDetails?.personal_details?.dateOfBirth
                          ? false
                          : "DOB is required",
                    })}
                    defaultValue={
                      userDetails?.personal_details?.dateOfBirth || ""
                    }
                  />{" "}
                  {errors.dateOfBirth && (
                    <p className="m input-error">Please fill DOB</p>
                  )}
                </div>

                <div className="col-lg-6 form-check text_box">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Gender <small style={{ color: "#ff0000" }}>*</small>
                  </label>
                  <div className="col-lg-12 p-0">
                    <div className="form-check form-check-inline">
                      <input
                        name="Gender"
                        id="male"
                        type="radio"
                        value="male"
                        readOnly
                        {...register("gender")}
                      />
                      <label
                        htmlFor="male"
                        className="f_p text_c f_400 ml-3 mb-0"
                      >
                        Male
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        name="Gender"
                        id="female"
                        type="radio"
                        value="female"
                        readOnly
                        {...register("gender")}
                      />
                      <label
                        htmlFor="female"
                        className="f_p text_c f_400 ml-3 mb-0"
                      >
                        Female
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        name="Gender"
                        id="female"
                        type="radio"
                        value="other"
                        readOnly
                        {...register("gender")}
                      />
                      <label
                        htmlFor="female"
                        className="f_p text_c f_400 ml-3 mb-0"
                      >
                        Other
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6  text_box">
                  <div className="mb-3">
                    <label className="f_p text_c f_400 m-0 mb-1">
                      Select Marital Status{" "}
                      <small style={{ color: "#ff0000" }}>*</small>
                    </label>
                    <Select
                      {...register("maritalstatus", {
                        required:
                          MaritalStatus ||
                          userDetails?.personal_details?.martialStatus
                            ? false
                            : "Marital Status required.",
                      })}
                      // value={
                      //   MaritalStatus === undefined
                      //     ? MaritalStatus
                      //     : MaritalStatus.value
                      // }
                      onChange={(MaritalStatus) => {
                        handleMaritalStatus(MaritalStatus.value);
                      }}
                      options={MaritalStatusList}
                      placeholder={
                        userDetails?.personal_details?.martialStatus ||
                        "Select Status"
                      }
                      className="select_box"
                      styles={customStyles}
                    />
                    {/* {errors.maritalstatus && (
                      <p className="m-0 input-error">
                        {errors.maritalstatus.message}
                      </p>
                    )} */}
                  </div>
                </div>
                <div className="col-lg-6 form-group text_box">
                  <div className="mb-3">
                    <label className="f_p text_c f_400 m-0 mb-1">
                      Select Occupation{" "}
                      <small style={{ color: "#ff0000" }}>*</small>
                    </label>
                    <Select
                      {...register("occupation", {
                        required:
                          OccupationName ||
                          userDetails?.personal_details?.occupation
                            ? false
                            : "Occupation required.",
                      })}
                      value={
                        OccupationName === undefined
                          ? OccupationName
                          : OccupationName.label
                      }
                      onChange={(OccupationName) => {
                        handleOccupationName(OccupationName);
                      }}
                      placeholder={
                        userDetails?.personal_details?.occupation ||
                        "Select Occupation"
                      }
                      options={OccupationListData}
                      classNamePrefix="select_box"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="mb-1">
                    Residential Details{" "}
                    <small style={{ color: "#ff0000" }}>*</small>
                  </h4>
                  <hr />
                </div>
                <div className="col-lg-6 form-group text_box">
                  <div className="mb-3">
                    <label className="f_p text_c f_400 m-0 mb-1">
                      Select State <small style={{ color: "#ff0000" }}>*</small>
                    </label>

                    <Select
                      {...register("state", {
                        required:
                          StateName || userDetails?.personal_details?.state
                            ? false
                            : "State required.",
                      })}
                      value={
                        StatesCode === null ? StatesCode : StatesCode.label
                      }
                      onChange={(StatesCode) => {
                        handleStatesDropdown(StatesCode);
                      }}
                      options={optionStatesCode}
                      placeholder={
                        userDetails?.personal_details?.state || "Select State"
                      }
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </div>

                <div className="col-lg-6 form-group text_box">
                  <div className="mb-3">
                    <label className="f_p text_c f_400 m-0 mb-1">
                      Select District
                      <small style={{ color: "#ff0000" }}>*</small>
                    </label>
                    <Select
                      {...register("district", {
                        required:
                          DistrictName ||
                          userDetails?.personal_details?.district
                            ? false
                            : "district required.",
                      })}
                      value={
                        DistrictCode === null
                          ? DistrictCode
                          : DistrictCode.label
                      }
                      onChange={(DistrictCode) => {
                        handleDistrictCode(DistrictCode);
                      }}
                      options={optionDistrictCode}
                      placeholder={
                        userDetails?.personal_details?.district ||
                        "Select District"
                      }
                      classNamePrefix="select2-selection"
                      isLoading={loader}
                    />
                  </div>
                </div>

                <div className="col-lg-4 form-group text_box">
                  <div className="mb-3">
                    <label className="f_p text_c f_400 m-0 mb-1">
                      Select Block <small style={{ color: "#ff0000" }}>*</small>
                    </label>

                    <Select
                      {...register("block", {
                        required:
                          BlockName || userDetails?.personal_details?.city
                            ? false
                            : true,
                        message: "Please Select Block",
                      })}
                      value={BlockCode === null ? BlockCode : BlockCode.label}
                      onChange={(BlockCode) => {
                        handleBlockCode(BlockCode);
                      }}
                      options={optionBlockCode}
                      placeholder={
                        userDetails?.personal_details?.city || "Select City"
                      }
                      classNamePrefix="select2-selection"
                    />
                    {errors.BlockName && (
                      <p className="m input-error">Select Block</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-2 form-group text_box">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Pin Code <small style={{ color: "#ff0000" }}>*</small>
                  </label>
                  <input
                    className="form-control"
                    name="pincode"
                    type="text"
                    placeholder="Pincode"
                    {...register("pincode", {
                      required: userDetails?.personal_details?.pincode
                        ? false
                        : "Pincode required",
                      minLength: {
                        value: 6,
                        message: "Pincode must be at least 6 digits",
                      },
                      maxLength: {
                        value: 6,
                        message: "Pincode cannot exceed 6 digits",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Only numbers are allowed",
                      },
                    })}
                    defaultValue={userDetails?.personal_details?.pincode || ""}
                  />

                  {errors.pincode && (
                    <p className="m input-error">{errors.pincode.message}</p>
                  )}
                </div>

                <div className="col-lg-6 form-group text_box">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Address <small style={{ color: "#ff0000" }}>*</small>
                  </label>
                  <input
                    className="form-control"
                    name="address"
                    type="text"
                    placeholder="Enter Address"
                    defaultValue={userDetails?.personal_details?.address}
                    {...register("address", {
                      required: userDetails?.personal_details?.pincode
                        ? false
                        : true,
                      message: "Address Required",
                    })}
                  />
                  {errors.address && (
                    <p className="m input-error">Address Required</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <h4 className="mb-1">
                    Other Details <small style={{ color: "#ff0000" }}>*</small>
                  </h4>

                  <hr />
                </div>
                <div className=" col-lg-6 form-group text_box">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    No. of Family Members{" "}
                    <small style={{ color: "#ff0000" }}>*</small>
                  </label>
                  <input
                    className="form-control w"
                    name="noOfMember"
                    type="number"
                    placeholder="Enter Number "
                    {...register("noOfMember", {
                      required: userDetails?.personal_details?.noOfMember
                        ? false
                        : "Number of nominee required",
                    })}
                    defaultValue={userDetails?.personal_details?.noOfMember}
                  />
                  {errors?.noOfMember && (
                    <p className="m input-error">Enter No. of member</p>
                  )}
                </div>

                <div className=" col-lg-6 form-group text_box">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Nominee Name <small style={{ color: "#ff0000" }}>*</small>
                  </label>
                  <input
                    className="form-control"
                    name="nomineeName"
                    type="text"
                    placeholder="Enter Nominee Name"
                    {...register("nomineeName", {
                      required: userDetails?.personal_details?.nomineeName
                        ? false
                        : "Nominee name is required",
                    })}
                    defaultValue={userDetails?.personal_details?.nomineeName}
                  />
                  {errors.nomineeName !== null ||
                    (errors.nomineeName !== "undefined" && (
                      <p className="m input-error">
                        Enter name of nominee <small>*</small>
                      </p>
                    ))}
                </div>

                <div className="col-lg-6 form-group text_box">
                  <div className="mb-3">
                    <label className="f_p text_c f_400 m-0 mb-1">
                      Select Relationship of Nominee{" "}
                      <small style={{ color: "#ff0000" }}>*</small>
                    </label>
                    <Select
                      required
                      {...register("nomineeRelationship", {
                        required:
                          Relationship ||
                          userDetails?.personal_details?.nomineeRelationship
                            ? false
                            : "Relationship Required",
                      })}
                      value={
                        Relationship === undefined
                          ? Relationship
                          : Relationship.value
                      }
                      onChange={(Relationship) => {
                        handleRelationship(Relationship.value);
                      }}
                      options={RelationshipList}
                      classNamePrefix="select2-selection"
                      placeholder={
                        userDetails?.personal_details?.nomineeRelationship ||
                        "Select Relationship"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <h4 className="mb-1">Document Details</h4>
                  <hr />
                </div>
                <div className=" col-lg-6 form-group text_box ">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Aadhar Number <small style={{ color: "#ff0000" }}>*</small>
                  </label>
                  <input
                    className="form-control w"
                    name="aadharNumber"
                    type="number"
                    placeholder="Enter Aadhar Number"
                    defaultValue={userDetails?.personal_details?.aadharNumber}
                    {...register("aadharNumber", {
                      required: userDetails?.personal_details?.aadharNumber
                        ? false
                        : "Aadhar number required",
                      maxLength: 12,
                      minLength: 12,
                    })}
                  />
                  {errors.aadharNumber && (
                    <p className="m input-error">Invalid Aadhar Number</p>
                  )}
                </div>

                <div className=" col-lg-6 form-group text_box">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    PAN Number <small style={{ color: "#ff0000" }}>*</small>
                  </label>
                  <input
                    name="panNumber"
                    type="text"
                    className="text-uppercase"
                    placeholder="Enter PAN Number"
                    defaultValue={userDetails?.personal_details?.panNumber}
                    required={
                      !userDetails?.personal_details?.panNumber ? true : false
                    }
                    {...register("panNumber", {
                      required: userDetails?.personal_details?.panNumber
                        ? false
                        : "Pan number required",
                      maxLength: 10,
                      minLength: 10,
                    })}
                  />
                  {errors.panNumber && (
                    <p className="m input-error">Invalid Pan Nmber</p>
                  )}
                </div>

                <div className="col-lg-6 form-group mb-4">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Upload Aadhar Card{" "}
                    <small style={{ color: "#ff0000" }}>*</small>
                    {/* <small>(Image Or Pdf Format Only*)</small> */}
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="AddharImg"
                    multiple
                    accept="image/jpeg,image/png,application/pdf"
                    name="aadhar_card"
                    {...register("aadhar_card", {
                      required: true,
                    })}
                  />
                  <small>(image or Pdf Format Only)</small>{" "}
                  <small style={{ color: "#ff0000" }}>max 5mb</small>
                  {errors.aadhar_card && (
                    <p className="m input-error">
                      Choose front and back side of Aadhar
                    </p>
                  )}
                </div>

                <div className="col-lg-6 form-group mb-4">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Upload Pan Card{" "}
                    <small style={{ color: "#ff0000" }}>*</small>
                    {/* <small>(Image Or Pdf Format Only*)</small> */}
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    name="pan_card"
                    accept="image/jpeg,image/png,application/pdf"
                    {...register("pan_card", {
                      required: true,
                    })}
                  />
                  <small>(image or Pdf Format Only)</small>{" "}
                  <small style={{ color: "#ff0000" }}>max 5mb</small>
                  {errors.pan_card && (
                    <p className="m input-error">Choose pan card</p>
                  )}
                </div>

                {/* //Profile photo */}
                <div className="col-lg-6 form-group mb-4">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Upload Your Profie picture{" "}
                    <small style={{ color: "#ff0000" }}>*</small>
                    {/* <small>(Image Or Pdf Format Only*)</small> */}
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/jpeg,image/png,application/pdf"
                    name="profile_picture"
                    {...register("profile_picture", {
                      required: true,
                    })}
                  />
                  <small>(passport size image)</small>{" "}
                  <small style={{ color: "#ff0000" }}>max 5mb</small>
                  {errors.profile_picture && (
                    <p className="m input-error">Choose profile picture</p>
                  )}
                </div>

                {/**** other Document */}

                <div className="col-lg-6 form-group text_box">
                  <div className="mb-3">
                    <label className="f_p text_c f_400 m-0 mb-1">
                      Select Other Document
                    </label>
                    <Select
                      {...register("other_document_type", {})}
                      value={
                        OtherDocName === undefined
                          ? OtherDocName
                          : OtherDocName.label
                      }
                      onChange={(OtherDocName) => {
                        handlOtherDocName(OtherDocName.value);
                        SetChangeEvent(OtherDocName);
                      }}
                      options={DocumenttypeListData}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </div>

                <div className=" col-lg-6 form-group text_box">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Other Document Number
                  </label>
                  <input
                    className="form-control w"
                    name="otherDocumentNumber"
                    type="text"
                    placeholder="Enter Document Number"
                    {...register("otherDocumentNumber", {
                      disabled: changeEvent == null ? true : false,
                    })}
                  />
                </div>

                <div className="col-lg-6 form-group mb-4">
                  <label className="f_p text_c f_400 m-0 mb-1">
                    Upload Document
                    {/* <small>(Image Or Pdf Format Only*)</small> */}
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="OtherDocImg"
                    name="other_document"
                    multiple
                    accept="image/jpeg,image/png,application/pdf"
                    {...register("other_document", {
                      disabled: changeEvent == null ? true : false,
                    })}
                  />
                  <small>(image or Pdf Format Only)</small>{" "}
                  <small style={{ color: "#ff0000" }}>max 5mb</small>
                </div>
              </div>

              <div className="col-lg-4 m-auto pb-5 mb-5 justify-content-center text-center">
                <button
                  type="submit"
                  className="saved_btn"
                  disabled={loading}
                  id="save_btn"
                  onClick={handleSubmit(onSubmit)}
                >
                  {!userDetails?.personal_details?.aadharNumber
                    ? "Save"
                    : "Update"}{" "}
                  &nbsp; {loading ? <Spinner size="sm"></Spinner> : ""}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KycDetails;
